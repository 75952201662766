import { Controller } from '@hotwired/stimulus';
import Swal from "sweetalert2";
import {useDispatch} from "stimulus-use";

/*
 * This is an example Stimulus controller!
 *
 * Any element with a data-controller="hello" attribute will cause
 * this controller to be executed. The name "hello" comes from the filename:
 * hello_controller.js -> "hello"
 *
 * Delete this file or adapt it for your use!
 */
export default class extends Controller {
    static values = {
        url: String,
        partnerName: String,
    }
    connect() {
        // useDispatch(this, { debug: true });
        // this.element.textContent = 'Hello Stimulus! Edit me in assets/controllers/hello_controller.js';
    }

    async displaySuppliers(event) {

                 //const response = await fetch(this.urlValue);

            let response = $.ajax({
                url: this.urlValue,
            })
            response.done(function (output_success) {
                //Code à jouer en cas d'éxécution sans erreur du script du PHP
                let response = output_success;
                $('#sidebar-restaurant').empty();

                $('#sidebar-restaurant').html(response);


             });
        }

    async displayMenuAdmin(event) {

        //const response = await fetch(this.urlValue);

        let response = $.ajax({
            url: this.urlValue,
        })
        response.done(function (output_success) {
            //Code à jouer en cas d'éxécution sans erreur du script du PHP
            let response = output_success;
            $("#sidebar").empty();

            $("#sidebar").html(response);


        });
    }
}

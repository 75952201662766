import Swal from "sweetalert2";
import $ from "jquery";
import SlimSelect from "slim-select";

let map=null;
let directionsService;
let directionsRenderer;
let markers = [];
let myPos = { lat: 17.91065111204945, lng: -62.83434028564455 };
let defaultPos = {lat: 17.91065111204945, lng: -62.83434028564455};
if(window.DelifoodArea == 'SXM'){
    myPos = { lat: 18.0622, lng: -63.0667 };
    defaultPos = { lat: 18.0622, lng: -63.0667 };
} else {

}
let customerPos= myPos;

console.log(window.DelifoodArea);
console.log(defaultPos);

export function initMap() {
    const myLatLng = defaultPos;

    directionsRenderer = new google.maps.DirectionsRenderer();
    directionsService = new google.maps.DirectionsService();

    map = new google.maps.Map(document.getElementById("map"), {
        zoom: 13,
        center: myLatLng,
        mapTypeId: 'hybrid'

    });
    if(Window.GmapUsedIn == 'front'){
        if(Window.GMAPCoord){
            refreshMap(Window.GMAPCoord)
        }else {
            geolocateMe(map);
        }
    }
    if(Window.GmapUsedIn == 'back'){
           if(Window.GMAPCoord){
            refreshMap(Window.GMAPCoord)
        }else {
            geolocateMe(map);
        }
        directionsRenderer.setMap(map);
    }

}

function deleteMarkers() {
    clearMarkers();
    markers = [];
}

// Sets the map on all markers in the array.
function setMapOnAll(map) {
    for (let i = 0; i < markers.length; i++) {
        markers[i].setMap(map);
    }
}

// Removes the markers from the map, but keeps them in the array.
function clearMarkers() {
    setMapOnAll(null);
}

export function refreshMap(pLatLng = null, pCustomer = null){
   // alert('coucou');
    if(map==null){
        initMap();
    }
    if(pLatLng==null){

        pLatLng = defaultPos;
    }else{
        if(pLatLng.lat==0){
            Swal.fire({
                position: 'center',
                icon: 'warning',
                title: 'Pas de coordonées géolocalisée',
                showConfirmButton: true,
                timer: 3000
            });
            pLatLng = defaultPos;
        }
    }

    clearMarkers();
    directionsRenderer.setMap(null);
    //calculateAndDisplayRoute(null);

    addMarker(pLatLng);
    map.setCenter(pLatLng);

   // geolocateMe(map);
    if(pCustomer != null) {
        customerPos = pLatLng;
        // document.getElementById("modalGoogleMapsTitle").innerHTML =
        //     "Géolocalisation <strong>" + pCustomer.name +
        //     " à " + pCustomer.district +
        //     " <br>Indication : " + pCustomer.addressName +
        //     "</strong> <br> " + pCustomer.commentForDelivery;
        document.getElementById("modalGoogleMapsTitle").innerHTML =
            "Géolocalisation <strong>" + pCustomer.name+"</strong>";

        let digicode = "";
        if(pCustomer.digicode !== null && pCustomer.digicode !== "" && pCustomer.digicode !== undefined){
            digicode = "<br>Code portail : " + pCustomer.digicode;
        }



        document.getElementById("deliveryComments").innerHTML =
             " Quartier : " + pCustomer.district +
             " <br>Indication : " + pCustomer.addressName +
            " <br>Commentaires : " + pCustomer.commentForDelivery + digicode;
    }


}
export function displayRoute(pLatLng, pCustomer){

    clearMarkers();

    addMarker(pLatLng);
    map.setCenter(pLatLng);

    geolocateMe(map);
    customerPos = pLatLng;

    calculateAndDisplayRoute(directionsService,directionsRenderer);
    document.getElementById("modalGoogleMapsTitle").innerHTML =
        "Géolocalisation <strong>"+pCustomer.name+
        " à "+pCustomer.district+
        "</strong> <br> "+pCustomer.commentForDelivery;

}

export function addMarker(pLatLng){
    var $draggable = true;

    if(Window.deliveryMan){
        $draggable = false;
    }

    const marker =    new google.maps.Marker({
        position: pLatLng,
        map,
       // title: "Hello World!",
        draggable: $draggable
    });

    if(Window.GmapUsedIn == 'front' || Window.GmapUsedIn == 'back' ){
        $(".checkout_latitude").val((marker.getPosition().lat()));
        $(".checkout_longitude").val((marker.getPosition().lng()));

        marker.addListener("dragend", () => {
            $(".checkout_latitude").val((marker.getPosition().lat()));
            $(".checkout_longitude").val((marker.getPosition().lng()));
        });

    }

    markers.push(marker);
}

let infoWindow;

export function geolocateMe(map){

    if (Window.isGeolocateMe == true){
        infoWindow = new google.maps.InfoWindow;
        // Try HTML5 geolocation.
        clearMarkers();
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(function(position) {
                var lat = position.coords.latitude;
                var lng = position.coords.longitude;

                if(window.DelifoodArea == 'SXM'){
                    if (lat > 17.99 && lat < 18.13) {
                        // document.getElementById("us2-lat").value = position.coords.latitude;
                    } else {
                        lat = 18.0622;
                    }
                    if (lng > -63.2 && lng < -63){
                    } else {
                        lng = -63.0667;
                    }
                } else {
                    if (lat > 17.87 && lat < 17.95) {
                        // document.getElementById("us2-lat").value = position.coords.latitude;
                    } else {
                        lat = 17.91065111204945;
                    }
                    if (lng > -62.9 && lng < -62.77){
                    } else {
                        lng = -62.83434028564455;
                    }
                }

                myPos = {
                    lat: lat,
                    lng: lng
                };

                map.setCenter(myPos);
                addMarker(myPos)

            }, function() {
                clearMarkers();
                addMarker(defaultPos)

            });
        } else {
            // Browser doesn't support Geolocation
            handleLocationError(false, infoWindow, map.getCenter());
            addMarker(defaultPos)
        }
    }

}
function handleLocationError(browserHasGeolocation, infoWindow, pos) {
    infoWindow.setPosition(pos);
    infoWindow.setContent(browserHasGeolocation ?
    'Error: The Geolocation service failed.' :
    'Error: Your browser doesn\'t support geolocation.');
    infoWindow.open(map);
}

export function calculateAndDisplayRoute(directionsService, directionsRenderer) {
    directionsRenderer.setMap(map);
    directionsService.route(
        {
            origin: myPos,
            destination: customerPos,
            travelMode: google.maps.TravelMode.DRIVING,
        },
        (response, status) => {
            if (status === "OK") {
                directionsRenderer.setDirections(response);
            } else {
                window.alert("Directions request failed due to " + status);
            }
        }
    );
}

export function displayOptionsSpe(productId, dishOptionParentId, dishOptionsListId){
    console.log(dishOptionParentId)
    console.log(dishOptionParentId.attributes["idselected"].value)
    console.log(dishOptionsListId)
    let idToHide  = dishOptionParentId.attributes["idselected"].value ;
    console.log(idToHide)
    $('.dishOptionsListId'+dishOptionsListId).addClass('d-none');
    $('.dishOptionsListId'+dishOptionsListId+' select').prop('disabled', true);
    $('.specialSelectDiv_'+idToHide+productId).removeClass('d-none');
    $('.specialSelectDiv_'+idToHide+productId+' select').prop('disabled', false);

    console.log(productId+idToHide);
}

export function displayOptions(productId){
    $('.divOptions'+productId).removeClass('d-none');
    $('#btnHideOptions'+productId).removeClass('d-none');
    $('#btnDisplayOptions'+productId).addClass('d-none');
}

export function hideOptions(productId){
    $('.divOptions'+productId).addClass('d-none');
    $('#btnHideOptions'+productId).addClass('d-none');
    $('#btnDisplayOptions'+productId).removeClass('d-none');
}


export function slimSelectDishOptionsList (id,required, maxOption, minOption, locale = 'fr'){
    // var message = "";
    // if(locale === 'en'){
    //     message = "Select your options";
    // } else {
    //     message = "Sélectionnez vos options";
    // }
    // new SlimSelect({
    //     select: id,
    //     limit: maxOption,
    //     placeholder: message,
    //     showSearch: false,
    // })
}

var script = document.createElement('script');
script.src =
    'https://maps.googleapis.com/maps/api/js?libraries=places,visualization&key=AIzaSyCmuS1qVxrWG2nqps0Jf4UGQIAhjFl_wLQ&v=weekly&callback=Window.initMap';
script.async=true;

document.head.appendChild(script);


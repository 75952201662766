import { Controller } from '@hotwired/stimulus';
import { useDispatch } from "stimulus-use";

export default class extends Controller {
    static values = {
        url: String,
    }
    connect() {
        useDispatch(this, { debug: true });
    }
    async addProduct(event) {
        var formName = event.currentTarget.dataset.formIdMaison;
        console.log(formName);

       const $form =$('form[name="'+formName+'"]');

$form[0].checkValidity()
        $.validator.messages.required = "Ce champ est requis / This field is required";
        $form.validate({
            // Specify validation rules
            rules: {
                // The key name on the left side is the name attribute
                // of an input field. Validation rules are defined
                // on the right side
                freeNote: "required",
            },
            // Specify validation error messages
            messages: {
                freeNote: "Merci de choisir une cuisson / Please choose a cooking",
            },
        });
        if($form.valid() && $form[0].checkValidity()){
            const response = await $.ajax({
                url: $form.prop('action'),
                method: $form.prop('method'),
                data: $form.serialize(),
            })
            $(".modal").modal('hide');

            $(".cart-icon-animated-"+formName).animate({maxWidth:'3.5rem'});
            $(".cart-icon-animated-"+formName).animate({maxWidth:'2.8rem'});

            this.dispatch('async:product:added', {
                response,
            })
        }


    }
}

import { Controller } from '@hotwired/stimulus';
import { useDispatch } from "stimulus-use";
import {initMap, geolocateMe, refreshMap, displayRoute} from "../my-helper";
import Swal from "sweetalert2";
import $ from "jquery";

export default class extends Controller {
    static values = {
        url: String,
        urlDashBoard: String,
        refreshInterval: Number,
        refreshMe: Boolean,

    }
    connect() {
        if (this.hasRefreshIntervalValue) {
            this.startRefreshing()
        }
    }
    disconnect() {
        this.stopRefreshing()
    }
    messageAlert(string){
        Swal.fire({
            position: 'top-end',
            icon: 'warning',
            title: string,
            showConfirmButton: false,
            timer: 3000
        })

    }
    displaySpinner(){
           if(document.getElementById('spinner-ajax').style.display == 'none'){

           document.getElementById('spinner-ajax').style.display = 'inline-block';
        }
    }
    hideSpinner(){
        document.getElementById('spinner-ajax').style.display = 'none';
    }
    startRefreshing() {
        this.hideSpinner();
        if (this.refreshMeValue == true) {
            this.displaySpinner();
            this.refreshTimer = setInterval(() => {
                this.refreshDashBoard()
            }, this.refreshIntervalValue)
            this.hideSpinner();
        }

    }
   async stopRefreshing() {
       if (this.refreshTimer) {
           clearInterval(this.refreshTimer)
       }
    }

    async submitForm(event) {

        event.preventDefault();
        var error = false;
        var formName = event.currentTarget.dataset.formName;
        var assignment = event.currentTarget.dataset.assignment;
        var alreadySent = event.currentTarget.dataset.alreadySent;
        var noMessage = event.currentTarget.dataset.noMessage;
        var isAuto = event.currentTarget.dataset.isAuto;

        let send = true;
        const $form = $('form[name="' + formName + '"]');
        var urlToCall = $form.prop('action') + '?query=' + assignment;
        if(isAuto == 1){
            var urlToCall = $form.prop('action') + '?isAuto=1';
        }

        if(alreadySent === "1"){
            if(confirm('Etes-vous sûr de renvoyer un email ?') === false){
                send = false
            }
        }
        if(noMessage === "1"){
            urlToCall = urlToCall+"&noMessage=1";
        }

        if(send === true){
            if($form[0].checkValidity())
            {
                this.displaySpinner();
                const response = await $.ajax({
                    url: urlToCall,
                    method: $form.prop('method'),
                    data: $form.serialize(),
                });



                if (response == 'ERROR_SMS' || response == 'ERROR_WITH_PHONE_NUMBER') {
                    error = 1;
                    this.messageAlert('Problème envoi sms')
                }

                if (error == false){
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: 'Opération réussie',
                        showConfirmButton: false,
                        backdrop: false,
                        timer: 1000
                    })
                }
                this.refreshDashBoard(event);
                this.hideSpinner();
            }
        }

    }

    async checkPurchaseItem(event) {
        const response = await fetch(event.currentTarget.dataset.urlCheckItem);

    }
    async changeWorkflow(event) {

        if(event.currentTarget.dataset.confirmMessage != ''){
            if(confirm(event.currentTarget.dataset.confirmMessage)){

            }else{
                return;
            }
        }


            const response = await fetch(event.currentTarget.dataset.urlChangeWorkflow,{
                headers:{
                    "X-Ajax-Header": "AnotherValue"
                }
            });
            if(response.status != 200){
                await Swal.fire({
                    title: 'Erreur',
                    text: "Merci d'affecter un livreur",
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
            }
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Opération réussie',
                showConfirmButton: false,
                backdrop: false,
                timer: 1000
            })
            this.refreshDashBoard(event);



    }

    showTimePicker(){
        $('input.timepicker').each(function(index){
            $(this).timepicker(
                {
                    timeFormat: 'H:mm',
                    interval: 5,
                    /*minTime: this.value,
                    maxTime: '6:00pm',
                    defaultTime: '11',*/
                    startTime: this.value,
                    dynamic: false,
                    dropdown: true,
                    scrollbar: true
                }
            );
        });
    }
    async refreshDashBoard(event) {
        this.displaySpinner();
        const response = await fetch(this.urlDashBoardValue);
        this.element.innerHTML = await response.text();
        this.showTimePicker();
        initMap();
        await this.stopRefreshing();
        $('.modal').modal('hide');
        $('body').removeClass('modal-open');
        $('.modal-backdrop').remove();
        this.hideSpinner();
    }


}

import { Controller } from '@hotwired/stimulus';
import {Modal} from "bootstrap";
// import $ from 'jquery'
import { useDispatch } from 'stimulus-use'

/*
 * This is an example Stimulus controller!
 *
 * Any element with a data-controller="hello" attribute will cause
 * this controller to be executed. The name "hello" comes from the filename:
 * hello_controller.js -> "hello"
 *
 * Delete this file or adapt it for your use!
 */
var modals = [];
export default class extends Controller {

    static targets=["modal","modalBody"]
    static values = {
        formUrl: String,
    }
    modal = null;

    connect() {
        console.log("COUCOU LOULOU")
        useDispatch(this, {debug: true});
    }

    async openModal(event){
        $('.modal').modal('hide');
        $('.modal-backdrop').remove()

        this.modal = new Modal(this.modalTarget);


        this.modalBodyTarget.innerHTML = await $.ajax(this.formUrlValue);
        this.modal.show();
    }

    async submitForm(event){
        event.preventDefault()
        const $form = $(this.modalBodyTarget).find('form');
        try{
            this.modalBodyTarget.innerHTML = await   $.ajax({
                url: this.formUrlValue,
                method: $form.prop('method'),
                data: $form.serialize()
            })
            window.location.reload()
            this.dispatch('success')
            this.modal.hide()

        }catch (e) {
            this.modalBodyTarget.innerHTML = e.responseText
        }



    }
    modalHidden() {
        console.log('it was hidden!');
    }

}

import { Controller } from '@hotwired/stimulus';
import Swal from "sweetalert2";

/*
 * This is an example Stimulus controller!
 *
 * Any element with a data-controller="hello" attribute will cause
 * this controller to be executed. The name "hello" comes from the filename:
 * hello_controller.js -> "hello"
 *
 * Delete this file or adapt it for your use!
 */
export default class extends Controller {
    static values = {
        url: String,
        locale: String,
        partnerName: String,
        urlEmptyCart: String,
        urlRedirectTo: String,
    }

    messageAlert(string,emptyCartBtn = false){
        if(emptyCartBtn){
            var btnConfirmText = "VIDER MON PANIER"
            var btnCancelText = "REVENIR A MA COMMANDE"
            if(this.localeValue == 'en'){
                btnConfirmText = "CLEAR MY CART"
                btnCancelText = "BACK TO MY ORDER"
            }


         var swalAlert=   Swal.fire({
                position: 'center',
                icon: 'warning',
                title: string,
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonText: btnConfirmText,
                cancelButtonText: btnCancelText,
            })


            swalAlert.then((result) => {
                if (result.isConfirmed) {
                    if (this.urlEmptyCartValue) {
                        window.location.replace(this.urlEmptyCartValue)
                    }

                }
            })

            document.getElementById('spinner-cart').style.display = 'none';

        }else{

            if(event.detail.response === 'DELIVERY_CLOSED'){
                var btnConfirmText = "Click & Collect"
                "Click & Collect"
                if(this.localeValue == 'en'){
                    btnConfirmText = "Click & Collect"
                }


              var swalAlert =   Swal.fire({
                    position: 'center',
                    icon: 'warning',
                    title: string,
                    showConfirmButton: true,
                    confirmButtonText: btnConfirmText,
                })

                swalAlert.then((result) => {
                    if (result.isConfirmed) {
                        if (this.urlRedirectToValue) {
                            window.location.replace(this.urlRedirectToValue)
                        }

                    }
                })

            }else{

                Swal.fire({
                    position: 'center',
                    icon: 'warning',
                    title: string,
                })
            }


        }

    }

    async refreshCart(event) {

        var locale = this.localeValue

        console.log(this.localeValue)

        if(document.getElementById('spinner-cart').style.display === 'none'){
            document.getElementById('spinner-cart').style.display = 'inline-block';
        }
        if(event.detail.response === 'DELIVERY_CLOSED'){
            var msg = 'Le service de livraison est momentanément fermé';
            if(locale === 'en'){
                msg = 'The delivery service is currently closed';
            }
            this.messageAlert(msg);

        }

        if(event.detail.response === 'RESTAURANT_CLOSED'){
            var msg = 'Ce partenaire est fermé';
            if(locale === 'en'){
                msg = 'This supplier is closed';
            }
            this.messageAlert(msg);

        }
        if(event.detail.response === 'SHIFT_DIFFERENT'){
            var msg = 'Vous essayez d\'ajouter un produit qui appartient à un autre créneau, veuillez vider le panier pour continuer';
            if(locale === 'en'){
                msg = 'You are trying to add a product that belongs to another slot, please empty your cart to continue';
            }
            this.messageAlert(msg);

        }
        if(event.detail.response === 'CART_ONLY_SHOPPING'){

            var msg ='Merci de faire 2 commandes distinctes'
            if(locale === 'en'){
                msg = 'Please do 2 distinct orders'
            }
            this.messageAlert(msg)

        }
        if(event.detail.response === 'CART_ONLY_BREAKFAST'){
            var msg = 'Merci de faire une 2eme commande après celle de votre petit déjeuner'
            if(locale === 'en'){
                msg = 'Please do a second order after your breakfast order'
            }
            this.messageAlert(msg)

        }
        if(event.detail.response === 'CART_ONLY_RESTAURANT'){

            var msg = 'Vous ne pouvez commandez que des produits restaurant'
            if(locale === 'en'){
                msg = 'You can only order restaurant product'
            }
            this.messageAlert(msg)

        }
        if(event.detail.response === 'CART_ONLY_ONE_RESTAURANT'){
            var msg = 'Vous ne pouvez commander que dans un seul restaurant à la fois.'
            if(locale === 'en'){
                msg = 'You can only order one restaurant at a time'
            }
            this.messageAlert(msg,true)
        }else {
            //const response = await fetch(this.urlValue);

            let response = $.ajax({
                url: this.urlValue,
            })
            response.done(function (output_success) {
                //Code à jouer en cas d'éxécution sans erreur du script du PHP
                let response = output_success;
                $('#cart-footer').empty();
                $('#cart-normal').empty();

                $('#cart-footer').html(response);
                $('#cart-normal').html(response);


             });


            const responseAmount = await fetch('/'+this.partnerNameValue+'/cart/amount');


            let amount = (await responseAmount.json()).cartAmount;


            if(amount >150 && this.partnerNameValue != "w" ){
                document.getElementById("cart-amount-header").innerHTML = (amount / 100).toFixed(2)+ ' €';
                document.getElementById("cart-amount-footer").innerHTML =(amount / 100).toFixed(2)+ ' €';
                document.getElementById('spinner-cart').style.display = 'none';
            }else{
                document.getElementById("cart-amount-header").innerHTML = '';
                document.getElementById("cart-amount-footer").innerHTML = '';
                document.getElementById('spinner-cart').style.display = 'none';
            }


            $("#cart-amount-footer").animate({fontSize: "2.5rem"},{backgroundColor:"#d4d4d4"});
            $("#cart-amount-footer-pill img").animate({width:'3rem'});
            $("#cart-amount-footer").animate({fontSize: "1rem"});
            $("#cart-amount-footer-pill img").animate({width:'1.5rem'});



            $(".picto-add-to-cart").animate({maxWidth:'3rem'});
            $(".picto-add-to-cart").animate({maxWidth:'2.8rem'});

        }

    }

    async removeItem(event) {
        const response = await fetch(event.currentTarget.dataset.urlRemoveItem);
        await this.refreshCart(event);
        //const response = await fetch(this.urlRemoveItemValue);
    }
    async deleteItem(event) {
        const response = await fetch(event.currentTarget.dataset.urlDeleteItem);
        await this.refreshCart(event);
        //const response = await fetch(this.urlRemoveItemValue);
    }

    async addItem(event) {
        const response = await fetch(event.currentTarget.dataset.urlAddItem);

        await this.refreshCart(event);

    }
}

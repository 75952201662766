import {Controller, defaultSchema} from '@hotwired/stimulus';
import {useDispatch} from "stimulus-use";

/*
 * This is an example Stimulus controller!
 *
 * Any element with a data-controller="hello" attribute will cause
 * this controller to be executed. The name "hello" comes from the filename:
 * hello_controller.js -> "hello"
 *
 * Delete this file or adapt it for your use!
 */
export default class extends Controller {

    static values = {
        url: String,
    }
    connect() {
        useDispatch(this, { debug: true });

    }

    async addProduct(event) {

        var str = this.urlValue;
        var idProduct = event.currentTarget.dataset.idProduct;
        var isAdmin = event.currentTarget.dataset.isAdmin;
        var discrProduct = event.currentTarget.dataset.discrProduct
        var url = str.replace('0',idProduct);
        if(discrProduct == ""){
          discrProduct = "SHOPPING"
        }

        const response = await $.ajax({
            url: url,
            method: 'POST',
            data: jQuery.param({ discrProduct: discrProduct}) ,
        })

        this.dispatch('async:product:added', {
            response,
        })
        // const response = await fetch(url);
        // this.dispatch('async:product:added', {
        //     response,
        // })

    }
}
